<template>
  <header id="header" :class="determineHeaderFooterClass">
    <div class="container">
      <div class="row">
        <div id="nav-toggle" @click.prevent="ocMenu()">
          <div class="bar bar-1"></div>
          <div class="bar bar-2"></div>
          <div class="bar bar-3"></div>
        </div>
        <app-logo :i18n='i18n["header-logo"]'></app-logo>
        <app-user class="user_show"></app-user>
        <div class="action-btns">
          <div class="row">
            <router-link v-if="isUSA && isStoreOpen" to="/resources/store/products/0d7ae91b-86ed-41c9-a339-4a8e2d4cc44a"
              class="pwts btn btn-secondary btn-outline">{{
                orderPWTsText
              }}</router-link>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex'
import { breakpoints } from '../../mixins/breakpoints'
import Logo from './Logo.vue'
import Search from './Search.vue'
import User from './User.vue'

export default {
  name: 'header-main',
  mixins: [breakpoints],
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          'header-main': {},
          'header-logo': {},
          'header-user': {},
          tcOrderPWTs: 'Order PWTs'
        }
      },
    },
    ocMenu: Function,
  },
  data() {
    return {
      orderPWTsText: 'Order PWTs'
    }
  },
  methods: {},
  computed: {
    ...mapGetters({
      isStoreOpen: 'menu/isStoreOpen',
      userLogin: 'user/userLogin'
    }),
    determineHeaderFooterClass() {
      return process.env.VUE_APP_API_ROUTE_ADDRESS.includes('tgiapi.gideons.org') ? 'prod-header' : 'beta-header'
    },
    isUSA() {
      return !!this.userLogin && !!this.userLogin.campnumber && this.userLogin.campnumber[0] === 'U'
    }
  },
  watch: {
    i18n(newVal) {
      if (newVal && newVal.tcOrderPWTs && typeof newVal.tcOrderPWTs === 'string' && newVal.tcOrderPWTs.length > 0) {
        this.orderPWTsText = newVal.tcOrderPWTs
      } else {
        this.orderPWTsText = 'Order PWTs'
      }
    },
  },
  components: {
    appLogo: Logo,
    appUser: User,
    appSearch: Search,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';

.prod-header {
  background-color: #003946;
}

.beta-header {
  background-color: #460000;
}

#header {
  * {
    color: #fff;
  }

  .container {
    padding-top: 29px;
    padding-bottom: 29px;

    @include breakpoint(sm) {
      padding: 15px 15px;
    }
  }

  .header-upper {
    padding: 29px 0;
  }

  .row {
    @include breakpoint(sm) {
      flex-wrap: nowrap;
      align-items: center;
    }

    .col {
      @include breakpoint(sm) {
        max-width: none;
        width: auto;
        flex: 1 1 auto;
      }
    }
  }

  #nav-toggle {
    display: none;
    width: 70px;
    flex: 0 0 auto;
    padding: 0 15px;

    @include breakpoint(sm) {
      display: block;
    }

    .bar {
      display: block;
      height: 5px;
      width: 100%;
      margin-bottom: 7px;
      background-color: #fff;
      border-radius: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.action-btns {
  @include breakpoint(sm) {
    margin-right: 20px
  }
}

.pwts {
  @include breakpoint(sm) {
    display: none;
  }
}

.btn {
  &.btn-secondary {
    &.btn-outline {
      width: 100%;
      padding: 10px 15px;
      background-color: transparent;
      border: 3px solid $secondary-color;
      color: $secondary-color;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 3.5px;
      line-height: 19px;
      margin-bottom: 2px;
      margin-right: 2px;
      border-radius: 5px;

      @include breakpoint(sm) {
        padding: 10px;
        font-size: 13px;
        letter-spacing: 3.2px;
        line-height: 18px;
      }

      &:hover {
        background-color: $secondary-color;
        color: #fff;
      }
    }
  }
}
</style>


<style lang="scss" scoped>
@import '@/styles/settings.scss';

.user_show {
  display: inline;

  @include breakpoint(sm) {
    display: none;
  }
}
</style>